const STRINGS = {
    HOME: "home",
    LATEST_NEWS: "Latest News",
    SUBSCRIBE: "Join Our FREE Mailing List",
    SUBSCRIBE_NOW: "<span style='font-weight: 700;'>FREE</span> Newsletter",
    ADDRESS: "",
    ADDRESS_STRING: "Kibbutz Ein Hashofet, 1923700, Israel",
    REG_NO: "516496429",
    TAX_ID: "15067 24673",
    PHONE: "0208 102 0709",
    SUBSCRIPTION_TAG: "AGE_HOLDINGS"
}

Object.freeze(STRINGS);

export {STRINGS}
