const URL = {
    WEBSITE: "https://www.agegroup.io",
    FACEBOOK_PAGE: "https://www.facebook.com/Pension-Times-103096144798202",
    TWITTER_HANDLE: "https://twitter.com/PensionTimes",
    INSTAGRAM_PAGE: "https://www.instagram.com/pension_times",
    INFO_EMAIL: "info@agegroup.io",
    YOUTUBE_CHANNEL: "https://www.youtube.com/channel/UCMPgDcTG0LBdmjunSSQUuDg",
    GOOGLE_NEWS: "https://news.google.com/publications/CAAqBwgKMKHSnwswrty3Aw?ceid=GB:en&oc=3"
}

Object.freeze(URL);

export {URL};
