const FOLLOW_US = {
    iconFillColor: "#195B9B",
    isFacebook: false,
    isTwitter: false,
    isInstagram: false,
    isYoutube: false,
    isGoogleNews: false
}

Object.freeze(FOLLOW_US);

export {FOLLOW_US};
