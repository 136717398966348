import React from 'react';
import Helmet from 'react-helmet';

import {metadata} from '../../../config.js';
import HeaderWrapper from "../header";

import "../../css/index.scss";
import {STRINGS} from "../../constants/strings";
import {URL} from "../../constants/urls";
import {Footer} from "@cg-squad/ui-components";

const intro = 'AgeGroup is the premier source of information, activities and products for seniors in an inclusive and non-political environment. \nThrough our digital universe our partners can access highly targeted communities and seniors based on AgeGroup’s first party data.'

class Layout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        return (
            <React.Fragment>
                <Helmet defaultTitle={metadata.defaultTitle} titleTemplate={metadata.titleTemplate}>
                    <html lang="en"/>
                    <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8"/>
                    <meta httpEquiv="X-UA-Compatible" content="IE=edge"/>
                    <meta httpEquiv="Content-Language" content="en"/>
                    <link title="timeline-styles" rel="shortcut icon" type="image/png" href="/images/favicon.png"/>
                    <link as="style" rel="stylesheet preload prefetch" href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,700;1,400;1,800&display=swap"/>
                </Helmet>
                <HeaderWrapper/>
                {this.props.children}
                <Footer copyright={'Age Group A.G Ltd'} hideMailLingList={true} intro={intro}
                        stringsConst={STRINGS} urlsConst={URL}/>
            </React.Fragment>
        )
    }
}

export default Layout
