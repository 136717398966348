// The standard metadata will be overwritten dynamically (articles, authorData)
module.exports = {
    metadata: {
        defaultTitle: 'AgeGroup',
        titleTemplate: '%s',
        author: 'AgeGroup',
        facebookId: 'fb:page_id',
        twitterId: 'twitter:account_id',
        url: 'https://www.agegroup.io',
        description: 'AgeGroup is the premier source of information, activities and products for seniors in an inclusive and non-political environment. Through our digital universe our partners can access highly targeted communities and seniors based on AgeGroup’s first party data.',
        shortDescription: 'AgeGroup is the premier source of information, activities and products for seniors in an inclusive and non-political environment.'
    }
};
