import React from 'react'
import {STRINGS} from "../../constants/strings";
import {URL} from "../../constants/urls";
import {Header} from '@cg-squad/ui-components';
import {FOLLOW_US} from "../../constants/followUs";
import "../../css/header.scss"

const HeaderWrapper = props => {

    const logoSizes = {
        menuLogoWidth: 300,
        menuLogoHeight: 40,
        headerLogoWidth: 300,
        headerLogoHeight: 40
    }

    const AdHeader = () => '';

    const categories = {
        nodes: [
            {
                slug: 'blogs',
                title: 'Blogs',
                children: [],
                position: 0
            },
            {
                slug: 'about-us',
                title: 'About Us',
                children: [],
                position: 0
            },
            {
                slug: 'contact-us',
                title: 'Contact Us',
                children: [],
                position: 0
            }
        ]
    }


    return (
        <>
            <Header path={props.path}
                    categoryPath={props.categoryPath}
                    subCategoryPath={props.subCategoryPath}
                    followUs={FOLLOW_US}
                    AdHeader={AdHeader}
                    stringsConst={STRINGS} urlsConst={URL}
                    allCategories={categories}
                    logoSizes={logoSizes}>
                <a href={"/contact-us"}
                   className="flex items-center contact-us py-2 px-1 mr-1 lg:px-4 rounded-default font-helvetica lg:font-bold text-xs lg:text-base bg-denim text-white cursor-pointer lg:uppercase">
                    Contact&nbsp;Us
                </a>
            </Header>
        </>
    )
}

export default HeaderWrapper
